import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { PageRoutes } from "../models/routes";
import FlexBox from "./FlexBox";
import { RedArrowRight } from "./RedArrow";

const useStyles = makeStyles((theme) => ({
  getStartedLink: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  getStartedLinkContainer: {
    marginRight: theme.spacing(2),
    justifyContent: "flex-end",
    "& > $linkContainer": {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  },
}));

const GetStartedLink = () => {
  const classes = useStyles();
  return (
    <FlexBox className={classes.getStartedLinkContainer}>
      <Link to={PageRoutes.Contact} className={classes.getStartedLink}>
        <RedArrowRight>Get Started</RedArrowRight>
      </Link>
    </FlexBox>
  );
};

export default GetStartedLink;
