import React from "react";
import classNames from "classnames";
import Typography, { TypographyProps } from "./Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontWeight: "bold",
  },
}));

interface PageTitleProps extends TypographyProps {
  className?: string;
}
const PageTitle: React.FC<PageTitleProps> = ({
  className,
  children,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Typography
      header
      variant="h4"
      className={classNames(classes.titleText, className)}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
