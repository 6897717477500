import React from "react";
import classNames from "classnames";

import FlexBox from "../../components/FlexBox";
import Typography from "../../components/Typography";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import ImageCard from "../../components/ImageCard";
import homeStagingImage from "../../media/services/homeStaging.png";
import NavLeftRight from "../../components/NavLeftRight";
import { PageRoutes } from "../../models/routes";
import PageTitle from "../../components/PageTitle";
import GetStartedLink from "../../components/GetStartedLink";
import BlurbHeader from "../../components/BlurbHeader";
import { DOWN_BREAK } from "../../styles";

const useStyles = makeStyles((theme) => ({
  homeStagingContentContainer: {
    flexDirection: "column",
    width: "100%",
    maxWidth: "100vw",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [DOWN_BREAK]: {
      paddingLeft: theme.spacing(4),
    },
  },
  title2: {
    textAlign: "center",
  },
  allStepsContainer: {
    overflowX: "auto",
  },
  stepContainer: {
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  subPage: {
    position: "relative",
    alignItems: "center",
  },
  columnDirection: {
    flexDirection: "column",
  },
  media: {
    height: "60vh",
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: "auto",
    },
  },
  imageOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  blurbContainer: {
    background: "#000000c3",
    color: "#efefef",
    padding: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  leftShift: {
    position: "relative",
    left: `-${theme.spacing(6)}px`,
    right: 0,
    paddingLeft: theme.spacing(12),
  },
  whiteSpacePre: {
    whiteSpace: "pre",
  },
  ghostBox: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#ffffff7d",
    top: theme.spacing(8),
    left: theme.spacing(8),
  },
  imageText: {
    zIndex: 1,
  },
}));

const HomeStagingPage = () => {
  const theme = useTheme();
  const isSmallOrLarger = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  return (
    <FlexBox flexDirection="column">
      <FlexBox className={classes.homeStagingContentContainer}>
        <PageTitle className={classes.title}>Home Staging</PageTitle>
        <FlexBox
          className={classNames(classes.subPage, {
            [classes.columnDirection]: !isSmallOrLarger,
          })}
        >
          <FlexBox flexShrink={0}>
            <ImageCard image={homeStagingImage} className={classes.media}>
              <div className={classes.imageOverlay}>
                <div className={classes.ghostBox} />
                <Typography header variant="h2" className={classes.imageText}>
                  <b>Why</b>
                </Typography>
                <Typography header variant="h2" className={classes.imageText}>
                  <b>Stage</b>
                </Typography>
                <Typography header variant="h2" className={classes.imageText}>
                  <b>With Us?</b>
                </Typography>
              </div>
            </ImageCard>
          </FlexBox>
          <div
            className={classNames(classes.blurbContainer, {
              [classes.leftShift]: isSmallOrLarger,
            })}
          >
            <BlurbHeader>
              <b>{`Profit Maximized. `}</b>
            </BlurbHeader>
            <Typography component="span">
              Staged homes frequently sell faster and for higher prices than
              unstaged homes.
            </Typography>
            <br />
            <br />
            <BlurbHeader>
              <b>{`Desirability Amplified. `}</b>
            </BlurbHeader>
            <Typography component="span">
              We stage with the Latest Trend in Design + Inventory. We utilize
              3D visualization technology to precisely curate the desirable
              lifestyle for your home with optimal comfort + livability +
              sophistication.
            </Typography>
            <br />
            <br />
            <BlurbHeader>
              <b>{`Stage Hassle-Free. `}</b>
            </BlurbHeader>
            <Typography component="span">
              Our goal is to make the process as simple and reliable for you as
              possible. More time for you to get the property sold. Leave the
              staging to us. See 5-Step Staging Process below.
            </Typography>
            <GetStartedLink />
          </div>
        </FlexBox>
      </FlexBox>
      <FlexBox className={classes.homeStagingContentContainer}>
        <PageTitle className={classes.title2}>5-Step Staging Process</PageTitle>
        <FlexBox
          className={classNames(classes.allStepsContainer, {
            [classes.columnDirection]: !isSmallOrLarger,
          })}
        >
          <FlexBox className={classes.stepContainer}>
            <BlurbHeader>Step 1: Walk-Thru*</BlurbHeader>
            <Typography>
              Our designer meets with you onsite to go over your design goals +
              budget and do a walk-thru of the space. We may take measurements
              as needed.
              <br />
              <br />
              *Social distance option available: Meet via video call. Client
              provides key or lockbox code for walk-thru access.
            </Typography>
          </FlexBox>
          <FlexBox className={classes.stepContainer}>
            <BlurbHeader>Step 2: Handshake</BlurbHeader>
            <Typography>
              In 1-2 days, Estimate is ready for your review. We set Staging
              Date and sign a contract to kickoff the design work. Shortest
              contract is 30-days. Monthly extensions are available.
            </Typography>
          </FlexBox>
          <FlexBox className={classes.stepContainer}>
            <BlurbHeader>Step 3: Design</BlurbHeader>
            <Typography>
              In 2-3 days, Our design is ready with layouts and furnishing
              pieces that have been carefully curated to maximize the sale
              potential of every room.
            </Typography>
          </FlexBox>
          <FlexBox className={classes.stepContainer}>
            <BlurbHeader>Step 4: Installation</BlurbHeader>
            <Typography>
              Prepping for delivery takes 2-3 days. On staging day, our crew
              brings all the furnishing and accessories to your door, carefully
              installs all the pieces under designer’s supervision, and leaves
              without any traces.
            </Typography>
          </FlexBox>
          <FlexBox className={classes.stepContainer}>
            <BlurbHeader>Step 5: Removal</BlurbHeader>
            <Typography>
              7-day advanced notice is required for staging removal. On removal
              day, our crew enters and remove only what we brought in.
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <NavLeftRight
        left={{ label: "View All Services", href: PageRoutes.Services }}
        right={{ label: "View Portoflio", href: PageRoutes.Portfolio }}
      />
    </FlexBox>
  );
};

export default HomeStagingPage;
