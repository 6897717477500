import React, { ElementType } from "react";
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  angeliqueBonques: {
    fontFamily: "AngelicBonques",
  },
}));

export interface TypographyProps extends MuiTypographyProps {
  header?: true;
  component?: ElementType;
}
const Typography = ({
  className,
  header,
  children,
  component = "p",
  ...typProps
}: TypographyProps) => {
  const classes = useStyles();
  return (
    <MuiTypography
      component={component}
      className={classNames(className, { [classes.angeliqueBonques]: header })}
      {...typProps}
    >
      {children}
    </MuiTypography>
  );
};

export default Typography;
