import { makeStyles } from "@material-ui/core";
import React from "react";
import { FC } from "react";
import Typography from "./Typography";

const useStyles = makeStyles((theme) => ({
  boldStatements: {
    whiteSpace: "pre-wrap",
    fontSize: 26,
  },
}));

const BlurbHeader: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography
      header
      variant="h6"
      component="span"
      className={classes.boldStatements}
    >
      {children}
    </Typography>
  );
};

export default BlurbHeader;
