import { makeStyles } from "@material-ui/core";

export const BREAKPOINT = 960;
export const DOWN_BREAK = `@media (max-width: ${BREAKPOINT - 0.05}px)`;
export const UP_BREAK = `@media (min-width: ${BREAKPOINT}px)`;

export const useStyles = makeStyles((theme) => ({
  titleAndDescriptionContainer: {
    flexDirection: "column",
    margin: `${theme.spacing(2)}px`,
  },
  imageAndBlurbContainer: {
    position: "relative",
    [DOWN_BREAK]: {
      flexDirection: "column-reverse",
    },
    [UP_BREAK]: {
      margin: theme.spacing(4),
      padding: `0 ${theme.spacing(6)}px`,
      alignItems: "center",
    },
  },
  image: {
    [DOWN_BREAK]: {
      width: "100vw",
    },
    [UP_BREAK]: {
      height: 360,
    },
  },
  blurbBox: {
    [DOWN_BREAK]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    [UP_BREAK]: {
      marginRight: `-${theme.spacing(8)}px`,
    },
    flexDirection: "column",
    background: "#000000c3",
    padding: `${theme.spacing(8)}px ${theme.spacing(8)}px`,
    alignContent: "center",
    color: "#efefef",
    "& > p": {
      marginTop: theme.spacing(2),
    },
    zIndex: 1,
  },
  boxButtonsContainer: {
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(2)}px`,
    flexGrow: 1,
    [DOWN_BREAK]: {
      flexDirection: "column",
    },
  },
  boxButton: {
    flex: "1 1 300px",
    height: "250px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [DOWN_BREAK]: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& > .MuiButton-label": {
      display: "grid",
      gridTemplateColumns: "100%",
      gridTemplateRows: "40% 60%",
      alignItems: "center",
    },
  },
  boxButtonLabelText: {
    border: "solid 1px #cc4125",
    padding: theme.spacing(1),
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  noShowOnMobile: {
    [DOWN_BREAK]: {
      display: "none",
    },
  },
  boxButtonGridItem1: {
    alignSelf: "flex-end",
  },
  boxButtonGridItem2: {
    alignSelf: "flex-start",
  },
  whiteSpacePre: {
    whiteSpace: "pre",
  },
  whiteBackground: {
    background: 'white',
    zIndex: 2,
  },
}));
