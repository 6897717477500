import React from "react";
import { makeStyles } from "@material-ui/core";
import FlexBox from "./FlexBox";
import PageTitle from "./PageTitle";
import { DOWN_BREAK, UP_BREAK, useStyles } from "../styles";
import Typography from "./Typography";
import classNames from "classnames";
import { PORTFOLIO_IMAGES } from "../pages/PortfolioPage";

type Author = {
  name: string;
  company: string;
};

type Testimonial = {
  quote: string;
  author: Author;
  image: string;
};
const TESTIMONIALS: Testimonial[] = [
  {
    quote:
      "I have worked with Ty on staging multiple properties that I have sold.  She is very prompt to respond, is reliable, and does a great job in staging properties.  I would highly recommend Ty Karges Home.",
    author: { name: "Sundeep Ambati", company: "Ambati Properties" },
    image: PORTFOLIO_IMAGES.one[0].image,
  },
  {
    quote:
      "I have worked with Ty on a number of staging projects on listings.  Each experience has been 5 stars.  She is a joy to work with, professional, upbeat, realistic, timely.  I've gotten strong positive feedback from the sellers and buyers agents uniformly.",
    author: { name: "Jeff Hansen", company: "eXp Realty" },
    image: PORTFOLIO_IMAGES.two[0].image,
  },
  {
    quote:
      "I have worked with many stagers in and around the Bay area, and she is by far in the top.",
    author: { name: "Amber DeLaere", company: "Compass" },
    image: PORTFOLIO_IMAGES.three[0].image,
  },
  {
    quote:
      "Ty and her talented team are fantastic to work with.  Her exceptional skills and keen eye for detail add immense value to every home she stages.  Ty's professionalism and pleasant demeanor make the collaboration a true pleasure.\n\nWe received numerous compliments on the staging she did for our listing, and we are confident that her work played a significant role in selling it well over the list price.",
    author: { name: "Jen M.", company: "" },
    image: PORTFOLIO_IMAGES.four[0].image,
  },
  {
    quote:
      "On trend, creative, great vision, understands spacial needs of a room, responsive, I can go on and on describing the unique qualities Ty possesses that make working with her so wonderful. It takes a team of professionals to navigate a client successfully through the selling process of their home and each of my team members plays an integral part. ... Give her a canvas and she creates the most beautiful designs that deliver just the right message of comfort, quality and style. She has always been on point. Not only has she worked with me in staging homes to sell but she has also worked with my buyer clients in the design of their new home. Give her a call and be ready to be amazed!",
    author: { name: "Arlene F.", company: "" },
    image: PORTFOLIO_IMAGES.seven[0].image,
  },
];

const useTestimonialStyles = makeStyles((theme) => ({
  testimonialContainer: {
    flexDirection: "column",
    [UP_BREAK]: {
      "& > $imageAndBlurbContainer:nth-child(even)": {
        flexDirection: "row",
        "& > $imageBox": {
          justifyContent: "flex-start",
        },
      },
    },
  },
  imageAndBlurbContainer: {
    [UP_BREAK]: {
      height: 400,
      padding: `${theme.spacing(2)}px ${theme.spacing(12)}px`,
      minWidth: 900,
      flexDirection: "row-reverse",
    },
    [DOWN_BREAK]: {
      flexDirection: "column-reverse",
      marginTop: theme.spacing(6),
    },
  },
  blurbBox: {
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    [UP_BREAK]: {
      width: "50%",
      padding: `0 ${theme.spacing(8)}px`,
    },
    [DOWN_BREAK]: {
      width: "100%",
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  imageBox: {
    width: "50%",
    justifyContent: "flex-end",
    [DOWN_BREAK]: {
      height: 200,
      width: "100%",
    },
  },
  imageContainer: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    objectFit: "cover",
  },
  quote: {
    whiteSpace: "break-spaces",
  },
}));

const TestimonialSection = () => {
  const classes = {
    ...useStyles(),
    ...useTestimonialStyles(),
  };
  return (
    <FlexBox flexDirection="column">
      <FlexBox className={classes.titleAndDescriptionContainer}>
        <PageTitle>Testimonials</PageTitle>
      </FlexBox>
      <FlexBox className={classes.testimonialContainer}>
        {TESTIMONIALS.map(({ quote, author, image }) => (
          <FlexBox className={classes.imageAndBlurbContainer}>
            <FlexBox className={classNames(classes.blurbBox)}>
              <Typography className={classes.quote}>
                <PageTitle component="span">"</PageTitle>
                {quote}
              </Typography>
              <Typography className={classes.whiteSpacePre}>
                {`— ${author.name}
  ${author.company}`}
              </Typography>
            </FlexBox>
            <FlexBox className={classes.imageBox}>
              <FlexBox className={classes.imageContainer}>
                <img
                  className={classes.image}
                  src={image}
                  alt={`Work done for ${author.name}`}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default TestimonialSection;
