import React, { useEffect } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { PageRoutes, ServicesPageRoutes } from "./models/routes";
import PageWrapper from "./pages/PageWrapper";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import PortfolioPage from "./pages/PortfolioPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import ComingSoonPage from "./pages/ComingSoonPage";
import "./App.css";
import { useSearchParam } from "./utils/searchParams";
import HomeStagingPage from "./pages/services/HomeStagingPage";
import InteriorDesignPage from "./pages/services/InteriorDesignPage";
import InstantHomePage from "./pages/services/InstantHomePage";

import AOS from "aos";
import "aos/dist/aos.css";
import BeachPage from "./pages/BeachPage";
import { ParallaxProvider, useParallaxController } from "react-scroll-parallax";

AOS.init();

const theme = createMuiTheme({
  palette: {
    primary: { main: "#cc4125" },
    secondary: blueGrey,
    background: {
      default: "white",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const IS_LAUNCHED = true;

const AppContent = () => {
  const localStorageDev = localStorage.getItem("dev");
  const isDev = useSearchParam("dev") !== null || localStorageDev !== null;
  useEffect(() => {
    if (!localStorageDev && isDev) {
      localStorage.setItem("dev", "true");
    }
  }, [isDev, localStorageDev]);

  useUpdateControllerOnRouteChange();

  if (process.env.NODE_ENV !== "development" && !(isDev || IS_LAUNCHED)) {
    return <ComingSoonPage />;
  }

  return (
    <PageWrapper>
      <Switch>
        <Route
          path={ServicesPageRoutes.HomeStaging}
          component={HomeStagingPage}
        />
        <Route
          path={ServicesPageRoutes.InteriorDesign}
          component={InteriorDesignPage}
        />
        <Route
          path={ServicesPageRoutes.InstantHome}
          component={InstantHomePage}
        />
        <Route path={PageRoutes.Services} component={ServicesPage} />
        <Route path={PageRoutes.Portfolio} component={PortfolioPage} />
        <Route path={PageRoutes.About} component={AboutPage} />
        <Route path={PageRoutes.Contact} component={ContactPage} />
        <Route path={PageRoutes.Beach} component={BeachPage} />
        <Redirect from={PageRoutes.Home} to={PageRoutes.Root} />
        <Route path={PageRoutes.Root} component={HomePage} />
      </Switch>
    </PageWrapper>
  );
};

function useUpdateControllerOnRouteChange() {
  const location = useLocation();
  const parallaxController = useParallaxController();

  useEffect(() => {
    parallaxController?.update();
  }, [location.pathname, parallaxController]);
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <AppContent />
      </ParallaxProvider>
    </ThemeProvider>
  );
}

export default App;
