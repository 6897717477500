import _ from "lodash";
import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Dialog,
  DialogProps,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Typography from "../components/Typography";
import FlexBox from "../components/FlexBox";
import ImageCard, { ImageCardProps } from "../components/ImageCard";
import { ReactComponent as LongArrowRight } from "../media/longArrowRight.svg";
import NavLeftRight from "../components/NavLeftRight";
import { PageRoutes } from "../models/routes";
import classNames from "classnames";
import SwipeableTextMobileStepper from "../components/SwipeableTextMobileStepper";
import PageTitle from "../components/PageTitle";
import { importAllImages } from "../utils/images";
import { useRouteMatch } from "react-router-dom";
import tyMurphyBedVideo from "../media/portfolio/videos/ty_murphy_bed.mov";
import quintoWayVideo from "../media/portfolio/videos/2760QuintoWay.mov";
import camborneAveVideo from "../media/portfolio/videos/4CamborneAve.mov";

const useStyles = makeStyles((theme) => ({
  portfolioContentContainer: {
    flexDirection: "column",
    padding: theme.spacing(4),
    width: "100%",
  },
  portfolioSubPage: {
    flexDirection: "column",
    "&:not(:first-child)": {
      marginTop: theme.spacing(6),
    },
  },
  portfolioTitleContainer: {
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  subtitle: {
    fontWeight: 200,
    whiteSpace: "pre",
  },
  gridItem: {
    alignItems: "center",
    display: "flex",
    "& > .MuiCard-root": {
      "&:hover $imageOverlay": {
        background: "rgb(0 0 0 / 80%)",
        opacity: 1,
      },
    },
  },
  imageOverlay: {
    padding: theme.spacing(2),
    opacity: 0,
    position: "absolute",
    bottom: 0,
    right: 0,
    transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  separator: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  viewLabel: {
    color: "white",
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  viewArrow: {
    fill: "white",
    height: "1.2em",
  },
  thumbnailContainer: {
    paddingBottom: "66.67%",
    height: 0,
    width: "100%",
    position: "relative",
  },
  thumbnailButton: {
    height: "100%",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
  },
  viewMore: {
    justifyContent: "flex-end",
  },
  video: {
    maxHeight: "calc(100vh - 106px)",
    maxWidth: "calc(100vw - 64px)",
  },
}));

type PortfolioCardProps = {
  image: string;
  onClick: ImageCardProps["onClick"];
  imageStyles?: React.CSSProperties;
  containerClass?: string;
  buttonClass?: string;
} & ImageCardProps;
const PortfolioCard = ({
  image,
  onClick,
  ...imageCardProps
}: PortfolioCardProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4} className={classes.gridItem}>
      <ImageCard image={image} onClick={onClick} {...imageCardProps}>
        <div className={classes.imageOverlay}>
          <FlexBox alignItems="center">
            <Typography header variant="h5" className={classes.viewLabel}>
              View
            </Typography>
            <LongArrowRight className={classes.viewArrow} />
          </FlexBox>
        </div>
      </ImageCard>
    </Grid>
  );
};

export const PORTFOLIO_IMAGES: Record<string, { image: string }[]> =
  _.mapValues(
    {
      one: importAllImages(
        require.context("../media/portfolio/1", false, /\.(png|jpe?g|svg)$/)
      ),
      two: importAllImages(
        require.context("../media/portfolio/2", false, /\.(png|jpe?g|svg)$/)
      ),
      three: importAllImages(
        require.context("../media/portfolio/3", false, /\.(png|jpe?g|svg)$/)
      ),
      four: importAllImages(
        require.context("../media/portfolio/4", false, /\.(png|jpe?g|svg)$/)
      ),
      five: importAllImages(
        require.context("../media/portfolio/5", false, /\.(png|jpe?g|svg)$/)
      ),
      six: importAllImages(
        require.context("../media/portfolio/6", false, /\.(png|jpe?g|svg)$/)
      ),
      seven: importAllImages(
        require.context("../media/portfolio/7", false, /\.(png|jpe?g|svg)$/)
      ),
      eight: importAllImages(
        require.context("../media/portfolio/8", false, /\.(png|jpe?g|svg)$/)
      ),
      nine: importAllImages(
        require.context("../media/portfolio/9", false, /\.(png|jpe?g|svg)$/)
      ),
      ten: importAllImages(
        require.context("../media/portfolio/10", false, /\.(png|jpe?g|svg)$/)
      ),
      eleven: importAllImages(
        require.context("../media/portfolio/11", false, /\.(png|jpe?g|svg)$/)
      ),
      twelve: importAllImages(
        require.context("../media/portfolio/12", false, /\.(png|jpe?g|svg)$/)
      ),
    },
    (value) => value.map((image) => ({ image }))
  );

const threeDImages = importAllImages(
  require.context("../media/portfolio/3D", false, /\.(png|jpe?g)$/)
);
const threeDGifs = importAllImages(
  require.context("../media/portfolio/3D", false, /\.(gif)$/)
);
const IS_SMALL_GIF = {} as Record<string, boolean>;
for (let i = 0; i < threeDImages.length; i++) {
  const gifRoomKey = `threeD${i}`;
  PORTFOLIO_IMAGES[gifRoomKey] = [{ image: threeDGifs[i] }];
  IS_SMALL_GIF[gifRoomKey] = true;
}

type RoomKey = keyof typeof PORTFOLIO_IMAGES;

const PORTFOLIO_ORDER = [
  "twelve",
  "eleven",
  "ten",
  "nine",
  "eight",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
] as RoomKey[];

// TODO: Include this in some kind of metadata that the owner can control without looking at code.
const THUMBNAIL_OFFSETS = {
  two: 24,
} as { [key in RoomKey]: number };

type PortfolioCarouselProps = {
  roomKey: RoomKey | null;
  onClose: DialogProps["onClose"];
};
const PortfolioCarousel = ({ roomKey, onClose }: PortfolioCarouselProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      open={!!roomKey}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth={false}
    >
      {roomKey && (
        <SwipeableTextMobileStepper
          maxImageHeight={fullScreen ? "100vh" : "100vh - 64px"} // 64px is the margin around a Mui-dialog
          steps={_.sortBy(PORTFOLIO_IMAGES[roomKey], "image")}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
};

const PortfolioPage = () => {
  const classes = useStyles();
  const [roomKey, setRoomKey] = useState<RoomKey | null>(null);
  const isOnRootPage = useRouteMatch({
    path: PageRoutes.Root,
    exact: true,
  });
  const nonEmptyPortfolioFolders = PORTFOLIO_ORDER.filter(
    (key) => !!PORTFOLIO_IMAGES[key].length
  );
  return (
    <FlexBox className={classes.portfolioContentContainer}>
      <FlexBox className={classes.portfolioSubPage}>
        <FlexBox className={classes.portfolioTitleContainer}>
          <PageTitle>Portfolio</PageTitle>
          <Typography
            className={classNames(classes.subtitle, classes.separator)}
            variant="h4"
          >
            {` | `}
          </Typography>
          <Typography className={classes.subtitle} variant="h4">
            {`Home Staging `}
          </Typography>
          <Typography className={classes.subtitle} variant="h4">
            {`+ Interior Design`}
          </Typography>
        </FlexBox>
        <Grid container spacing={4}>
          {nonEmptyPortfolioFolders.map(
            (key, i) =>
              (!isOnRootPage || i < 6) && (
                <PortfolioCard
                  key={`${key}-${i}`}
                  image={PORTFOLIO_IMAGES[key][0].image}
                  data-aos="fade-right"
                  onClick={() => setRoomKey(key)}
                  containerClass={classes.thumbnailContainer}
                  buttonClass={classes.thumbnailButton}
                  imageStyles={
                    _.has(THUMBNAIL_OFFSETS, key)
                      ? { top: THUMBNAIL_OFFSETS[key], position: "relative" }
                      : undefined
                  }
                />
              )
          )}
        </Grid>
        {isOnRootPage && (
          <NavLeftRight
            className={classes.viewMore}
            right={{ label: "View More", href: PageRoutes.Portfolio }}
          />
        )}
        <PortfolioCarousel roomKey={roomKey} onClose={() => setRoomKey(null)} />
      </FlexBox>
      {!isOnRootPage && (
        <FlexBox className={classes.portfolioSubPage}>
          <FlexBox marginBottom={2}>
            <PageTitle>Portfolio</PageTitle>
            <Typography className={classes.subtitle} variant="h4">
              {` | 3D`}
            </Typography>
          </FlexBox>
          <Grid container spacing={4}>
            {threeDImages.map((image, i) => (
              <PortfolioCard
                key={`${image}-${i}`}
                image={image}
                onClick={() => setRoomKey(`threeD${i}`)}
              />
            ))}
          </Grid>
          <FlexBox marginBottom={2} marginTop={4}>
            <PageTitle>Videos</PageTitle>
          </FlexBox>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <video
                src={tyMurphyBedVideo}
                autoPlay
                loop
                muted
                className={classes.video}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <video
                src={quintoWayVideo}
                autoPlay
                loop
                muted
                className={classes.video}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <video
                src={camborneAveVideo}
                autoPlay
                loop
                muted
                className={classes.video}
              />
            </Grid>
          </Grid>
        </FlexBox>
      )}
      {!isOnRootPage && (
        <NavLeftRight
          left={{ label: "View Services", href: PageRoutes.Services }}
          right={{ label: "Contact Us", href: PageRoutes.Contact }}
        />
      )}
    </FlexBox>
  );
};

export default PortfolioPage;
