import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "../components/Typography";
import FlexBox from "../components/FlexBox";
import aboutPageImage1 from "../media/about/aboutPage2.jpg";
import aboutPageImage2 from "../media/about/aboutPage3.jpg";
import NavLeftRight from "../components/NavLeftRight";
import { PageRoutes } from "../models/routes";
import PageTitle from "../components/PageTitle";
import { useRouteMatch } from "react-router-dom";

const DOWN_BREAK = "@media (max-width: 799.95px)";
const UP_BREAK = "@media (min-width: 800px)";

const useStyles = makeStyles((theme) => ({
  titleAndDescriptionContainer: {
    flexDirection: "column",
    margin: `${theme.spacing(2)}px`,
  },
  labelText: {
    textTransform: "uppercase",
    fontFamily: "AngelicBonques",
    fontWeight: 600,
  },
  blurbAndImageContainer: {
    position: "relative",
    gridGap: 20,
    [DOWN_BREAK]: {
      flexDirection: "column-reverse",
    },
    [UP_BREAK]: {
      margin: theme.spacing(4),
      padding: `0 ${theme.spacing(6)}px`,
      alignItems: "center",
    },
  },
  image: {
    [DOWN_BREAK]: {
      width: "100vw",
    },
    [UP_BREAK]: {
      width: '100%',
    },
  },
  blurbBox: {
    [DOWN_BREAK]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
    flexDirection: "column",
    background: "#000000c3",
    padding: `${theme.spacing(8)}px ${theme.spacing(8)}px`,
    alignContent: "center",
    color: "#efefef",
    "& > p": {
      marginTop: theme.spacing(2),
    },
    zIndex: 1,
  },
  rightImageAndBlurb: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gridGap: 20,
  },
  leftImageContainer: {
    position: 'relative',
  },
  videoContainer: {
    position: "relative",
    paddingBottom: "56.25%",
    height: 0,
    width: "100vw",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
}));

const AboutPage = () => {
  const classes = useStyles();
  const isOnRootPage = useRouteMatch({
    path: PageRoutes.Root,
    exact: true,
  });

  return (
    <FlexBox flexDirection="column">
      <FlexBox className={classes.titleAndDescriptionContainer}>
        <PageTitle>About</PageTitle>
      </FlexBox>
      <FlexBox className={classes.blurbAndImageContainer}>
        <div className={classes.leftImageContainer}>
          <img
            className={classes.image}
            src={aboutPageImage2}
            alt="Ty sitting in a lounge chair at one of her staged homes, looking off into the distance."
          />
        </div>
        <div className={classes.rightImageAndBlurb}>
          <img className={classes.image} src={aboutPageImage1} alt="Ty smiling and sitting at the foot of the bed."/>
        <FlexBox className={classes.blurbBox}>
          <Typography header variant="h4" className={classes.labelText}>
            Ty Karges
          </Typography>
          <Typography>
            Owner and Principal Designer, Ty delivers livable modern designs
            with the utmost attention to every detail for home-staging and
            interior-design jobs alike. Her designs often incorporate
            mid-century and transitional elements to deliver signature looks and
            warmth to the space. Nonetheless, Ty designs with fluidity and
            flexibility to suit clients’ needs.
          </Typography>
          <Typography>
            Managing your project is a breeze when working with Ty. She is an
            accomplished project manager who understands the importance of
            timeline and budget. With extensive background in engineering,
            functionality and longevity are also amongst the top elements in her
            beautiful designs.
          </Typography>
          <Typography>
            Ty currently resides in San Jose. Her love for design + food +
            culture has driven her to start traveling on her own at the age of
            14 and has traveled to many parts of the world. She is a paw-parent
            of one fluff-nugget, a level-4 yogi, and a reluctant board game
            champion.
          </Typography>
        </FlexBox>
        </div>
      </FlexBox>
      {!isOnRootPage && (
        <NavLeftRight
          left={{ label: "View Portfolio", href: PageRoutes.Portfolio }}
          right={{ label: "Contact Us", href: PageRoutes.Contact }}
        />
      )}
    </FlexBox>
  );
};

export default AboutPage;
