import React, { FC } from "react";
import classNames from "classnames";
import { Box, BoxProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flexBox: {
    display: "flex",
  },
}));

const FlexBox: FC<BoxProps> = ({ children, className, ...otherProps }) => {
  const classes = useStyles();
  return (
    <Box className={classNames(classes.flexBox, className)} {...otherProps}>
      {children}
    </Box>
  );
};

export default FlexBox;
