import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import FlexBox from "./FlexBox";
import { useHistory } from "react-router-dom";
import { PageRoutes } from "../models/routes";

const useStyles = makeStyles((theme) => ({
  headerLabelContainer: {
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "nowrap",
  },
  headerLabel: {
    fontFamily: "AngelicBonques",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    lineHeight: 0.9,
    [theme.breakpoints.down("xs")]: {
      fontSize: 36,
    },
    "@media (max-width: 400px)": {
      fontSize: 32,
    },
    "@media (max-width: 330px)": {
      fontSize: 30,
    },
  },
  studioLabel: {
    fontFamily: "AngelicBonquesScript",
    fontSize: 24,
  },
  labelSvg: {
    height: 72,
  },
  subheaderText: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
    "@media (max-width: 330px)": {
      fontSize: 10,
    },
  },
}));

type HeaderLabelProps = { className?: string };
const HeaderLabel = ({ className }: HeaderLabelProps) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <FlexBox
      className={classNames(classes.headerLabelContainer, className)}
      onClick={() => history.push(PageRoutes.Root)}
    >
      <Typography className={classes.headerLabel} variant="h3">
        TY KARGES HOME
      </Typography>
      <Typography color="primary" className={classes.subheaderText}>
        HOME STAGING | INTERIOR DESIGN | SILICON VALLEY
      </Typography>
    </FlexBox>
  );
};

export default HeaderLabel;
