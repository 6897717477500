import React, { useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import FlexBox from "./FlexBox";
import { ReactComponent as ThreeLineMenuIcon } from "../media/threeLineMenuIcon.svg";
import MenuDrawer from "./MenuDrawer";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  headerMenuIconContainer: {
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  headerMenuIconButton: {
    borderRadius: "25%",
  },
}));

const HeaderMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <>
      <FlexBox className={classes.headerMenuIconContainer}>
        <IconButton
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          className={classes.headerMenuIconButton}
        >
          <ThreeLineMenuIcon />
        </IconButton>
      </FlexBox>
      <MenuDrawer
        className={classes.backdrop}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default HeaderMenu;
