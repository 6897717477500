import React from "react";
import { makeStyles } from "@material-ui/core";
import FlexBox from "./FlexBox";
import HeaderLabel from "./HeaderLabel";
import HeaderMenu from "./HeaderMenu";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: "sticky",
    top: 0,
    width: "100%",
    flexDirection: "column",
    zIndex: theme.zIndex.drawer - 1, // Needed to set the stacking context above the siblings Content and Footer
    borderBottom: `1px solid #9c9c9c`,
    background: "rgb(255 255 255 / 80%)",
  },
  headerContentContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  headerItem: {
    flexGrow: 1,
    justifyContent: "center",
  },
  logoSvg: {
    height: 72,
  },
  topBlackBar: {
    width: "100%",
    height: theme.spacing(1),
    background: "black",
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <FlexBox className={classes.headerContainer}>
      <div className={classes.topBlackBar}></div>
      <FlexBox className={classes.headerContentContainer}>
        <HeaderMenu />
        <FlexBox className={classes.headerItem}>
          <HeaderLabel />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Header;
