import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme) => ({
  whiteBackground: {
    background: 'white',
    zIndex: 2,
    position: 'relative',
  },
}))

export const WhiteDiv: FC = ({children}) => {
  const classes = useStyles();
  return <div className={classes.whiteBackground}>{children}</div>;
};
