import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "../components/Typography";
import classNames from "classnames";
import FlexBox from "./FlexBox";
import { ReactComponent as InstagramLogo } from "../media/instagramLogo.svg";
import { ReactComponent as HouzzLogo } from "../media/houzzLogo.svg";
// import { ReactComponent as FacebookLogo } from '../media/facebookLogo.svg';
// import { ReactComponent as YelpLogo } from '../media/yelpLogo.svg';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
    borderTop: "solid 1px #3c3c3c",
    background: "white",
    zIndex: 3,
  },
  footerContent: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    color: "black",
    zIndex: 2,
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  contactGroup: {
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  contactPair: {
    alignItems: "center",
    whiteSpace: "pre",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  contactLink: {
    color: "black",
    textDecoration: "none",
    display: "flex",
  },
  logoLink: {
    "& > svg": {
      fill: "black",
      stroke: "black",
    },
  },
  blackBoxLink: {
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  columnIfSmall: {
    [theme.breakpoints.down("xs")]: {
      "& > *:not(:last-child)": {
        marginRight: 0,
        marginLeft: 0,
      },
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const FooterBottom = () => {
  const classes = useStyles();
  return (
    <FlexBox
      className={classNames(classes.contactGroup, classes.columnIfSmall)}
    >
      <FlexBox className={classes.contactPair}>
        <Typography header>
          <b>Call:</b>
        </Typography>
        <a className={classes.contactLink} href="tel:+14082287196">
          <Typography>(408) 228-7196</Typography>
        </a>
      </FlexBox>
      <FlexBox className={classes.contactPair}>
        <Typography header>
          <b>Email:</b>
        </Typography>
        <a
          className={classes.contactLink}
          href="mailto:ty@tykarges.com?subject=About Ty Karges Home..."
        >
          <Typography>ty@tykarges.com</Typography>
        </a>
      </FlexBox>
    </FlexBox>
  );
};

const FooterTop = () => {
  const classes = useStyles();
  return (
    <FlexBox className={classNames(classes.contactPair, classes.columnIfSmall)}>
      <Typography header>
        <b>Keep In Touch:</b>
      </Typography>
      <FlexBox className={classes.blackBoxLink}>
        <a
          className={classNames(classes.contactLink, classes.logoLink)}
          href="http://instagram.com/tykarges"
        >
          <InstagramLogo />
        </a>
        <a
          className={classNames(classes.contactLink)}
          href="https://www.houzz.com/pro/tykargeshome/ty-karges-home-staging-interior-design"
        >
          <HouzzLogo />
        </a>
      </FlexBox>
      {/* <FacebookLogo />
        <YelpLogo /> */}
    </FlexBox>
  );
};

const Footer = () => {
  const classes = useStyles();
  return (
    <FlexBox className={classes.footerContainer}>
      <FlexBox className={classes.footerContent}>
        <FooterTop />
        <FooterBottom />
      </FlexBox>
    </FlexBox>
  );
};

export default Footer;
