export enum ContactReason {
  None = "none",
  HomeStaging = "home staging",
  InteriorDesign = "interior design",
}

export const initialContactForm = {
  name: "",
  email: "",
  phone: "",
  message: "",
  reason: ContactReason.None,
  sqft: 0,
  numBedrooms: 0,
  numBathrooms: 0,
  location: "",
  date: "",
  budget: "",
  rooms: "",
};
export type ContactForm = typeof initialContactForm;

export const validateContactForm = (values: ContactForm) => {
  const errors = {} as Record<keyof ContactForm, string>;
  if (!values.email && !values.phone) {
    errors.email = "Either email or phone is required";
    errors.phone = "Either email or phone is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};
