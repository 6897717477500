import React from "react";
import FlexBox from "./FlexBox";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { RedArrowLeft, RedArrowRight } from "./RedArrow";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  navRightLeftContainer: {
    margin: `${theme.spacing(2)}px 0`,
    justifyContent: "space-between",
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  containerLeft: {
    marginLeft: theme.spacing(2),
    "& > $linkContainer": {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      },
    },
  },
  containerRight: {
    marginRight: theme.spacing(2),
    "& > $linkContainer": {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  },
}));

type Nav = {
  label: string;
  href: string;
};
type NavLeftRightProps = {
  left?: Nav;
  right?: Nav;
  className?: string;
};
const NavLeftRight = ({ left, right, className }: NavLeftRightProps) => {
  const classes = useStyles();
  return (
    <FlexBox className={classNames(classes.navRightLeftContainer, className)}>
      {left && (
        <FlexBox className={classes.containerLeft}>
          <Link to={left.href} className={classes.linkContainer}>
            <RedArrowLeft>{left.label}</RedArrowLeft>
          </Link>
        </FlexBox>
      )}
      {right && (
        <FlexBox className={classes.containerRight}>
          <Link to={right.href} className={classes.linkContainer}>
            <RedArrowRight>{right.label}</RedArrowRight>
          </Link>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default NavLeftRight;
