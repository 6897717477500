import React, { FC } from "react";
import FlexBox from "../components/FlexBox";
import Header from "../components/Header";
import { makeStyles } from "@material-ui/core";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    minHeight: "100vh",
    flexDirection: "column",
  },
  contentContainer: {
    overflow: "hidden",
    zIndex: 1,
  },
  footerWrapper: {
    background: 'white',
    zIndex: 2,
  },
}));

const PageWrapper: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <FlexBox className={classes.pageContainer}>
      <Header />
      <FlexBox className={classes.contentContainer}>
        {children}
      </FlexBox>
      <Footer />
    </FlexBox>
  );
};

export default PageWrapper;
