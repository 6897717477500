import React, { useState } from "react";
import {
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Collapse,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ReactComponent as TyKargesLogo } from "../media/tykargesLogo.svg";
import Typography from "./Typography";
import { PageRoutes, ServicesPageRoutes } from "../models/routes";
import { useHistory } from "react-router-dom";
import FlexBox from "./FlexBox";
import { IS_TOUCH_SCREEN } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "100%",
    maxWidth: "900px",
    top: "104px",
    height: "calc(100% - 104px)",
    "& .MuiTypography-root": {
      fontFamily: "AngelicBonques",
    },
  },
  subServicesContainer: {
    justifyContent: "space-around",
    paddingLeft: theme.spacing(7),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: theme.spacing(9),
    },
  },
  mainItemText: {
    [theme.breakpoints.up("md")]: {
      fontSize: 60,
    },
  },
  subItemText: {
    [theme.breakpoints.up("md")]: {
      fontSize: 26,
    },
  },
}));

type MenuDrawerProps = {} & DrawerProps;
const MenuDrawer = ({ onClose, ...drawerProps }: MenuDrawerProps) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isWide = useMediaQuery(theme.breakpoints.up("md"));
  const pathname = history.location.pathname;
  const [open, setOpen] = useState(false);
  const handleOnClose = (evt: React.MouseEvent) => {
    setOpen(false);
    onClose && onClose(evt, "backdropClick");
  };
  const onClickRoute =
    (route: PageRoutes | ServicesPageRoutes) => (evt: React.MouseEvent) => {
      handleOnClose(evt);
      setOpen(false);
      history.push(route);
    };
  return (
    <Drawer
      onClose={handleOnClose}
      {...drawerProps}
      PaperProps={{ className: classes.drawerPaper }}
    >
      <List>
        <ListItem
          onClick={onClickRoute(PageRoutes.Services)}
          button
          selected={pathname === PageRoutes.Services}
          onMouseEnter={() => setOpen(true)}
        >
          <ListItemText
            inset
            disableTypography
            primary={
              <Typography header variant={isWide ? "h2" : "h3"}>
                Services
              </Typography>
            }
          />
        </ListItem>
        <Collapse in={open || IS_TOUCH_SCREEN} timeout="auto" unmountOnExit>
          <ListItem>
            <FlexBox className={classes.subServicesContainer} width="100%">
              <Button onClick={onClickRoute(ServicesPageRoutes.HomeStaging)}>
                <Typography header variant="h5" className={classes.subItemText}>
                  Home Staging
                </Typography>
              </Button>
              <Button onClick={onClickRoute(ServicesPageRoutes.InteriorDesign)}>
                <Typography header variant="h5" className={classes.subItemText}>
                  Interior Design
                </Typography>
              </Button>
              <Button onClick={onClickRoute(ServicesPageRoutes.InstantHome)}>
                <Typography header variant="h5" className={classes.subItemText}>
                  Instant Home
                </Typography>
              </Button>
            </FlexBox>
          </ListItem>
        </Collapse>
        <ListItem
          onClick={onClickRoute(PageRoutes.Portfolio)}
          button
          selected={pathname === PageRoutes.Portfolio}
        >
          <ListItemText
            inset
            disableTypography
            primary={
              <Typography header variant="h3" className={classes.mainItemText}>
                Portfolio
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          onClick={onClickRoute(PageRoutes.About)}
          button
          selected={pathname === PageRoutes.About}
        >
          <ListItemText
            inset
            disableTypography
            primary={
              <Typography header variant="h3" className={classes.mainItemText}>
                About
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          onClick={onClickRoute(PageRoutes.Contact)}
          button
          selected={pathname === PageRoutes.Contact}
        >
          <ListItemText
            inset
            disableTypography
            primary={
              <Typography header variant="h3" className={classes.mainItemText}>
                Contact
              </Typography>
            }
          />
        </ListItem>
        <ListItem button onClick={onClickRoute(PageRoutes.Root)}>
          <ListItemText
            inset
            disableTypography
            primary={
              <>
                <Typography
                  header
                  variant="h3"
                  className={classes.mainItemText}
                >
                  Home
                </Typography>
                <TyKargesLogo width={64} />
              </>
            }
          />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default MenuDrawer;
