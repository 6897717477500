import React from 'react';
import { makeStyles } from "@material-ui/core";
import FlexBox from "../components/FlexBox";
import sloMoDogBeachSrc from '../media/sloMoDogBeach.mp4';

const useStyles = makeStyles({
  videoContainer: {
    position: "relative",
    paddingBottom: "56.25%",
    height: 0,
    width: "100vw",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
});

const BeachPage = () => {
  const classes = useStyles();
  return (
    <FlexBox className={classes.videoContainer}>
    <video muted id="background-video" loop autoPlay className={classes.video}>
      <source src={sloMoDogBeachSrc} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  </FlexBox>
  );
};

export default BeachPage;