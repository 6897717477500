import React from "react";
import HeaderLabel from "../components/HeaderLabel";
import FlexBox from "../components/FlexBox";
import { Typography, makeStyles } from "@material-ui/core";
import { ReactComponent as TyKargesLogo } from "../media/tykargesLogo.svg";

const useStyles = makeStyles((theme) => ({
  logoSvg: {
    height: 72,
  },
  headerLabelContainer: {
    alignItems: "center",
  },
}));

const ComingSoonPage = () => {
  const classes = useStyles();
  return (
    <FlexBox flexDirection="column" alignItems="center">
      <TyKargesLogo className={classes.logoSvg} />
      <HeaderLabel className={classes.headerLabelContainer} />
      <Typography>
        <em>Coming Soon!!</em>
      </Typography>
    </FlexBox>
  );
};

export default ComingSoonPage;
