import axios from "axios";
import { ContactForm } from "../models/ContactForm";

const appStartTime = Date.now();

export const postContactForm = async (form: ContactForm) => {
  console.debug(`POST Contact Form: `, form);
  const response = await axios.get(
    process.env.REACT_APP_GOOGLE_SHEETS_ENDPOINT as string,
    {
      params: {
        ...form,
        appDuration: (Date.now() - appStartTime) / 1000,
      },
    }
  );
  console.debug(`Response Contact Form: `, response);
  return response;
};
