import { makeStyles, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import FlexBox from "../../components/FlexBox";
import GetStartedLink from "../../components/GetStartedLink";
import ImageCard from "../../components/ImageCard";
import NavLeftRight from "../../components/NavLeftRight";
import PageTitle from "../../components/PageTitle";
import Typography from "../../components/Typography";
import instantHomeImage from "../../media/services/instantHome.png";
import { PageRoutes } from "../../models/routes";
import { DOWN_BREAK, UP_BREAK } from "../../styles";

const useStyles = makeStyles((theme) => ({
  homeStagingContentContainer: {
    flexDirection: "column",
    width: "100%",
    [UP_BREAK]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [DOWN_BREAK]: {
      paddingLeft: theme.spacing(4),
    },
  },
  title2: {
    textAlign: "center",
  },
  subPage: {
    position: "relative",
    alignItems: "center",
  },
  columnDirection: {
    flexDirection: "column",
  },
  media: {
    height: "60vh",
    width: "auto",
    [DOWN_BREAK]: {
      width: "100vw",
      height: "auto",
    },
  },
  imageOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  blurbContainer: {
    background: "#000000c3",
    color: "#efefef",
    padding: theme.spacing(4),
    [DOWN_BREAK]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  leftShift: {
    position: "relative",
    left: `-${theme.spacing(6)}px`,
    right: 0,
    paddingLeft: theme.spacing(12),
  },
  boldStatements: {
    whiteSpace: "pre-wrap",
    fontSize: 26,
  },
  ghostBox: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#ffffff7d",
    top: theme.spacing(8),
    left: theme.spacing(8),
  },
  imageText: {
    zIndex: 1,
  },
}));

const InteriorDesignPage = () => {
  const isSmallOrLarger = useMediaQuery(UP_BREAK);
  const classes = useStyles();
  return (
    <FlexBox flexDirection="column">
      <FlexBox className={classes.homeStagingContentContainer}>
        <PageTitle className={classes.title}>Instant Home</PageTitle>
        <FlexBox
          className={classNames(classes.subPage, {
            [classes.columnDirection]: !isSmallOrLarger,
          })}
        >
          <FlexBox flexShrink={0}>
            <ImageCard image={instantHomeImage} className={classes.media}>
              <div className={classes.imageOverlay}>
                <div className={classes.ghostBox} />
                <Typography header variant="h2" className={classes.imageText}>
                  <b>Why Style</b>
                </Typography>
                <Typography header variant="h2" className={classes.imageText}>
                  <b>Your</b>
                </Typography>
                <Typography header variant="h2" className={classes.imageText}>
                  <b>Rental?</b>
                </Typography>
              </div>
            </ImageCard>
          </FlexBox>
          <div
            className={classNames(classes.blurbContainer, {
              [classes.leftShift]: isSmallOrLarger,
            })}
          >
            <Typography
              header
              variant="h6"
              component="span"
              className={classes.boldStatements}
            >
              <b>{`Rent + Occupancy Rate Maximized. `}</b>
            </Typography>
            <Typography component="span">
              Pictures are worth a thousand words to travelers and renters
              alike. We understand the lifestyle that they seek and incorporate
              it into all aspects of our designs, making your space more
              desirable than others on the same platform. Giving you the design
              edge to qualify for premium experiences such as Airbnb Plus or
              Airbnb Luxe.
            </Typography>
            <br />
            <br />
            <Typography
              header
              variant="h6"
              component="span"
              className={classes.boldStatements}
            >
              <b>{`Social Media Buzzed. `}</b>
            </Typography>
            <Typography component="span">
              Our designs are very instagramable! Renters are more likely to
              post on their social media with enjoyable experiences, which
              serves as free marketing for you.
            </Typography>
            <br />
            <br />
            <Typography
              header
              variant="h6"
              component="span"
              className={classes.boldStatements}
            >
              <b>{`Time Saved. `}</b>
            </Typography>
            <Typography component="span">
              Your time is valuable. Let us be that 1-stop shop to get your
              rental property looking at its best. We provide turnkey project
              management from start to finish. If you just want to hand us the
              key and move in when it is ready, no problem!
            </Typography>
            <GetStartedLink />
          </div>
        </FlexBox>
      </FlexBox>
      <NavLeftRight
        left={{ label: "View All Services", href: PageRoutes.Services }}
        right={{ label: "View Portfolio", href: PageRoutes.Portfolio }}
      />
    </FlexBox>
  );
};

export default InteriorDesignPage;
