import React, { useCallback, useState } from "react";
import { DialogProps, makeStyles, useTheme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeftRounded";
import ChevronRight from "@material-ui/icons/ChevronRightRounded";
import SwipeableViews from "react-swipeable-views";
import {
  autoPlay,
  bindKeyboard,
  virtualize,
} from "react-swipeable-views-utils";
import classNames from "classnames";
import { StepperDots, STEPPER_DOTS_HEIGHT } from "./StepperDots";
import { mod } from "../utils/base";
import FlexBox from "./FlexBox";
import { IS_TOUCH_SCREEN, SLIDE_DURATION_MS } from "../utils/constants";
import { Close } from "@material-ui/icons";

const AutoPlaySwipeableViews = virtualize(
  bindKeyboard(autoPlay(SwipeableViews))
);

const HEADER_HEIGHT = 50;

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: HEADER_HEIGHT,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
  },
  img: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: `calc(100vh - ${theme.spacing(8) + 100}px)`,
    [theme.breakpoints.down("sm")]: {
      maxHeight: `calc(100vh - ${HEADER_HEIGHT + STEPPER_DOTS_HEIGHT}px)`, // header + footer
    },
    objectFit: "contain",
  },
  imgNoFooter: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`, // header + footer
  },
  chevron: {
    opacity: 0,
    "& > svg": {
      height: "4rem",
      width: "4rem",
    },
  },
  leftRightButton: {
    position: "absolute",
    top: 0,
    cursor: "pointer",
    height: "100%",
    width: "30%",
    display: "flex",
    alignItems: "center",
    "&:hover > $chevron": {
      opacity: 1,
    },
    "&:active > $chevron > svg": {
      color: theme.palette.action.selected,
    },
  },
  leftButton: {
    left: 0,
    justifyContent: "flex-start",
  },
  rightButton: {
    right: 0,
    justifyContent: "flex-end",
  },
  leftRightButtonsContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  closeButton: {
    marginRight: theme.spacing(2),
  },
  closeIcon: {
    height: "1rem",
  },
}));

type CloseButtonProps = {
  onClose: DialogProps["onClose"];
};
const CloseButton = ({ onClose }: CloseButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      onClick={(evt) => onClose && onClose(evt, "backdropClick")}
      className={classes.closeButton}
    >
      Close
      <Close className={classes.closeIcon} />
    </Button>
  );
};

type LeftRightButtonsProps = {
  onLeft: React.MouseEventHandler<HTMLDivElement>;
  onRight: React.MouseEventHandler<HTMLDivElement>;
};
const LeftRightButtons = ({ onLeft, onRight }: LeftRightButtonsProps) => {
  const classes = useStyles();
  return (
    <>
      <div
        onClick={onLeft}
        className={classNames(classes.leftRightButton, classes.leftButton)}
      >
        <div className={classes.chevron}>
          <ChevronLeft color="action" />
        </div>
      </div>
      <div
        onClick={onRight}
        className={classNames(classes.leftRightButton, classes.rightButton)}
      >
        <div className={classes.chevron}>
          <ChevronRight color="action" />
        </div>
      </div>
    </>
  );
};

type SwipeableTextMobileStepperProps = {
  steps: { image: string; label?: string }[];
  start?: number;
  onClose?: DialogProps["onClose"];
  autoPlay?: boolean;
  maxImageHeight?: string;
};
function SwipeableTextMobileStepper({
  steps,
  start = 0,
  onClose,
  autoPlay = false,
  maxImageHeight,
}: SwipeableTextMobileStepperProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(start);
  const maxSteps = steps.length;
  const activeCircleIndex = mod(activeStep, maxSteps);

  const incrementImage = useCallback(
    () => setActiveStep((prevIdx) => prevIdx + 1),
    []
  );
  const decrementImage = useCallback(
    () => setActiveStep((prevIdx) => prevIdx - 1),
    []
  );

  const headerExists = steps[activeCircleIndex].label || onClose;
  const footerExists = maxSteps > 1;

  return (
    <FlexBox flexDirection="column" alignItems="center">
      {headerExists ? (
        <Paper square elevation={0} className={classes.header}>
          <Typography>{steps[activeCircleIndex].label}</Typography>
          <CloseButton onClose={onClose} />
        </Paper>
      ) : null}
      <FlexBox position="relative">
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={setActiveStep}
          enableMouseEvents
          autoplay={autoPlay}
          interval={SLIDE_DURATION_MS}
          disabled={maxSteps <= 1}
          slideRenderer={({ index, key }) => {
            const { image, label } = steps[mod(index, maxSteps)];
            let maxHeight = maxImageHeight;
            if (footerExists) maxHeight += ` - ${STEPPER_DOTS_HEIGHT}px`;
            if (headerExists) maxHeight += ` - ${HEADER_HEIGHT}px`;
            maxHeight = `calc(${maxHeight})`;
            return (
              <div key={key} className={classes.imgContainer}>
                <img
                  className={classNames(classes.img, {
                    [classes.imgNoFooter]: maxSteps <= 1,
                  })}
                  style={maxImageHeight ? { maxHeight } : undefined}
                  src={image}
                  alt={label}
                />
              </div>
            );
          }}
        />
        {!IS_TOUCH_SCREEN && maxSteps > 1 ? (
          <LeftRightButtons onLeft={decrementImage} onRight={incrementImage} />
        ) : null}
      </FlexBox>
      {maxSteps > 1 && (
        <StepperDots
          numDots={maxSteps}
          activeDot={activeCircleIndex}
          onDotClick={(evt, i) => {
            i !== activeCircleIndex && setActiveStep(i);
          }}
        />
      )}
    </FlexBox>
  );
}

export default SwipeableTextMobileStepper;
