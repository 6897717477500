import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardMediaProps,
} from "@material-ui/core";

export type ImageCardProps = CardMediaProps & {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonClass?: string;
  containerClass?: string;
  imageStyles?: React.CSSProperties;
  delay?: number;
};
const ImageCard = ({
  image,
  title,
  children,
  className,
  onClick,
  buttonClass,
  containerClass,
  imageStyles,
  delay = 0,
}: ImageCardProps) => {
  return (
    <Card elevation={2} className={containerClass}>
      <CardActionArea onClick={onClick} className={buttonClass}>
        <CardMedia
          data-aos-delay={delay}
          component="img"
          className={className}
          image={image}
          title={title}
          style={imageStyles}
        />
        {children}
      </CardActionArea>
    </Card>
  );
};

export default ImageCard;
