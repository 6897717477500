import React from "react";
import { makeStyles } from "@material-ui/core";
import FlexBox from "./FlexBox";
import classNames from "classnames";

const STEPPER_PADDING = 8;
const STEPPER_DOT_HEIGHT = 8;
export const STEPPER_DOTS_HEIGHT = STEPPER_DOT_HEIGHT + 2 * STEPPER_PADDING;

const useStyles = makeStyles((theme) => ({
  stepperDotsContainer: {
    padding: STEPPER_PADDING,
    background: "white",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 400,
    flexGrow: 1,
  },
  stepperDot: {
    width: STEPPER_DOT_HEIGHT,
    height: STEPPER_DOT_HEIGHT,
    margin: "0 4px",
    borderRadius: "50%",
    backgroundColor: "rgba(0, 0, 0, 0.26)",
  },
  activeStepperDot: {
    backgroundColor: theme.palette.primary.main,
  },
  pointer: {
    cursor: "pointer",
  },
}));

export type StepperDotsProps = {
  numDots: number;
  activeDot: number;
  onDotClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    dotIdx: number
  ) => void;
};
export const StepperDots = ({
  numDots,
  activeDot,
  onDotClick,
}: StepperDotsProps) => {
  const classes = useStyles();
  return (
    <FlexBox className={classes.stepperDotsContainer}>
      {Array(numDots)
        .fill(1)
        .map((v, i) => (
          <div
            key={i}
            className={classNames(classes.stepperDot, {
              [classes.activeStepperDot]: i === activeDot,
              [classes.pointer]: !!onDotClick,
            })}
            onClick={(evt) => onDotClick && onDotClick(evt, i)}
          />
        ))}
    </FlexBox>
  );
};
