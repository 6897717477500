import _ from "lodash";
import { useCallback, useMemo, useReducer, useState, Reducer } from "react";

// TYPES
export interface Fetcher<T = any> {
  (arg: any): Promise<T>;
}

export enum FetchedStatusString {
  Idle = "IDLE",
  Pending = "PENDING",
  Success = "SUCCESS",
}

interface FetchedErrorStatus {
  message: string;
  code: number;
}

type FetchedStatus = FetchedStatusString | FetchedErrorStatus;

/**
 * @typedef {Object} FetchedResource
 * @property {*} data - The actual fetched data
 * @property {FetchedStatus} status - 'IDLE' | 'PENDING' | 'SUCCESS' | <{message: string, code: number}>error
 * @property {number} timestamp - The last time the resource was modified
}*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchedResource<T = any> {
  data: T;
  status: FetchedStatus;
  timestamp: number;
}

// TODO(jkarges): Fill this out.
type AnyAction = any;

export interface ActionDescriptor {
  type: string;
  creator: (...args: any[]) => AnyAction;
}

// ACTION TYPE NAMING UTILS

const toActionTypeCase = (str: string) => _.snakeCase(str).toUpperCase();
const toAttemptingType = (str: string) => toActionTypeCase(`ATTEMPTING_${str}`);
// const toCancelType = (str: string) => toActionTypeCase(`CANCEL_${str}`); // TODO(jkarges): Abortable fetch
const toSuccessType = (str: string) => toActionTypeCase(`SUCCESS_${str}`);
const toFailureType = (str: string) => toActionTypeCase(`FAILURE_${str}`);
const toSettingType = (str: string) => toActionTypeCase(`SET_${str}`);

// ACTIONS

interface Parser<T = any, R = T> {
  (value: T): R;
}

interface FetchingActionOpts<T, R> {
  parser?: Parser<T, R>;
}

/**
 * Make a fetching creator from a name, the async fetcher function, and an optional parser
 * The "type" will be set to the type-cased version of the name
 * The creator function returns an async thunk to be dispatched to the
 *  redux-thunk middleware, and updates the store
 * @template T - The (promised) return type of the fetcher
 * @template R - The return type of the optional parser (defaults to T)
 * @param {string} name - Name of the action.  The 'type' is set to the
 *  capitalized SNAKE_CASE version of that name.
 * @param {Fetcher<T>} fetcher - The asynchronous fetching function.
 * @param {FetchingActionOpts<T,R>=} fetchingActionOpts - Optional object to
 * customize the fetching action.
 * @param {Parser=} [fetchingActionOpts.parser=_.identity] - Optional function to re-parse
 *  the output of the fetcher from type T to type R.
 * @returns {ActionDescriptor}
 */
export function makeFetchingActionCreator<T = any, R = T>(
  name: string,
  fetcher: Fetcher<T>,
  { parser = _.identity }: FetchingActionOpts<T, R> = {}
) {
  const type = toActionTypeCase(name);
  const successCreator = makeActionCreator(toSuccessType(type));
  const attemptingCreator = makeActionCreator(toAttemptingType(type));
  // const cancelCreator = makeActionCreator(toCancelType(type)); // TODO(jkarges): Abortable fetch
  const failureCreator = makeActionCreator(toFailureType(type));
  return {
    type,
    creator: (opts: any) => {
      return async (dispatch: React.Dispatch<AnyAction>) => {
        const timestamp = Date.now();
        try {
          dispatch(attemptingCreator({ opts, timestamp }));
          const response = await fetcher(opts);
          const data = parser(response);
          dispatch(successCreator({ opts, data, timestamp }));
        } catch (err) {
          console.log(err);
          dispatch(failureCreator({ opts, err, timestamp }));
        }
      };
    },
  };
}

// Make a generic action creator that adds the type property to any input object
export const makeActionCreator = (type: string) => {
  return ({ ...args }) => ({
    type,
    ...args,
  });
};

// Make a simple action creator that sets the value of propName as propValue
export function makeSimpleSettingActionCreator<T = any>(propName: string) {
  const type = toSettingType(propName);
  return {
    type,
    creator: (propValue: T) => ({
      type,
      [propName]: propValue,
    }),
  };
}

// REDUCERS
// Same as a ReduxReducer but the input state can't be undefined.  Only for use
// in internal reducers where you're sure the state isn't undefined.

interface FetchedResourceReducerOpts<T> {
  successReducer?: (state: any, action: any) => any;
  allowStaleRequests?: boolean;
}

/**
 * Make a reducer function for a fetched resource.  It will take care of
 *  updating data as well as the timestamp and status
 * @template T
 * @param {string} name - The action name.  Same as the name given to
 *  makeFetchingActionCreator, or its 'type' property
 * @param {T} initialData - initial value of the 'data' property of the
 *  fetched resource
 * @param {FetchedResourceReducerOpts=} fetchedReducerOpts - Optional object to
 * customize the reducer.
 * @param {Reducer<FetchedResource<T>>=} [fetchedReducerOpts.successReducer]
 *  - Optional custom success reducer if you want the success action to do
 *  something other than just filling the 'data' property, and setting 'status'
 *  to "SUCCESS"
 * @param {boolean=} [fetchedReducerOpts.allowStaleRequests=false] - Set to true
 * if you don't want to prevent old requests from updating the state.  For
 * example, maybe you had 2 timeseries requests, and your custom successReducer
 * keeps both in an array.  If this was set to false, and the first request came
 * back after the second, it would be ignored.
 * @returns {ReduxReducer<FetchedResource<T>>} The reducer function for a
 *  fetched resource
 */
export const makeFetchedResourceReducer = <T = any>(
  name: string,
  initialData: T,
  {
    successReducer,
    allowStaleRequests = false,
  }: FetchedResourceReducerOpts<T> = {}
): Reducer<FetchedResource<T>, AnyAction> => {
  const attemptingType = toAttemptingType(name);
  const successType = toSuccessType(name);
  const failureType = toFailureType(name);

  const reducers = {
    [attemptingType]: (state, action) => ({
      ...state,
      status: FetchedStatusString.Pending,
      timestamp: action.timestamp,
    }),
    [failureType]: (state, action) => ({
      ...state,
      status: {
        message: _.get(action, "err.message", "unknown error"),
        code: _.get(action, "err.response.status", 500),
      },
    }),
    [successType]:
      successReducer ||
      ((state, action) => {
        return {
          ...state,
          data: action.data,
          status: FetchedStatusString.Success,
        };
      }),
  } as Record<string, Reducer<FetchedResource<T>, AnyAction>>;
  return (
    state = {
      data: initialData,
      status: FetchedStatusString.Idle,
      timestamp: 0,
    },
    action = { type: undefined }
  ) => {
    const actionType =
      allowStaleRequests || isRequestFresh(name, state, action)
        ? action.type
        : null;
    return {
      ...state,
      ..._.invoke(reducers, actionType, state, action),
    };
  };
};

// A request is fresh if it hasn't been requested yet, or if the timestamp on
// the action matches the timestamp of when the resource was requested.
const isRequestFresh = (name = "", state: FetchedResource, action: any) =>
  action.type === toAttemptingType(name) ||
  action.timestamp === state.timestamp;

type statusIsFunctionType = (status: FetchedStatus) => boolean;
const statusIsIdle: statusIsFunctionType = (status) =>
  status === FetchedStatusString.Idle;
const statusIsPending: statusIsFunctionType = (status) =>
  status === FetchedStatusString.Pending;
const statusIsSuccess: statusIsFunctionType = (status) =>
  status === FetchedStatusString.Success;
const statusIsError: statusIsFunctionType = _.isObject;

const anyFetchedResourceIs =
  (fn: statusIsFunctionType) =>
  (...listOfFetchedResources: FetchedResource[]) =>
    _.some(
      listOfFetchedResources,
      (resource) => _.has(resource, "status") && fn(resource.status)
    );

/**
 * Util functions to condition on the status of a fetched resource
 * @function
 * @param {...FetchedResource} Any number of fetched resources
 * @returns {boolean} whether any of the fetched resources are Pending,
 *  Success, or Error
 *
 * @example
 * const isPending = useSelector(state => anyIsPending(state.scenes, state.carBench.metadata));
 */
export const anyIsIdle = anyFetchedResourceIs(statusIsIdle);
export const anyIsPending = anyFetchedResourceIs(statusIsPending);
export const anyIsSuccess = anyFetchedResourceIs(statusIsSuccess);
export const anyIsError = anyFetchedResourceIs(statusIsError);

export const errorMessage = (response: FetchedResource) => {
  if (!anyIsError(response)) return null;
  return (response.status as FetchedErrorStatus).message;
};

// HOOKS

type UseFetchedResourceOpts<T, R> = FetchingActionOpts<T, R> &
  FetchedResourceReducerOpts<R> & { initialData: R };

/* Returned state looks like {data, status, timestamp}*/
export const useFetchedResource = <T, R = T, F extends Fetcher<T> = Fetcher<T>>(
  fetcher: F,
  initialFetchOpts = {} as UseFetchedResourceOpts<T, R>
) => {
  const [fetchOpts] = useState(initialFetchOpts);
  const { initialData, successReducer, parser, allowStaleRequests } = fetchOpts;
  const { action, reducer, initialState } = useMemo(() => {
    const name = _.uniqueId(fetcher.name);
    const action = makeFetchingActionCreator(name, fetcher, { parser });
    const reducer = makeFetchedResourceReducer(name, initialData, {
      successReducer,
      allowStaleRequests,
    });
    const initialState = reducer(
      {
        data: initialData,
        status: FetchedStatusString.Idle,
        timestamp: 0,
      },
      { type: undefined }
    ); // Hack 2 arguments in to get the initial fetched resource state
    return { action, reducer, initialState };
  }, [fetcher, initialData, parser, successReducer, allowStaleRequests]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const fetch = useCallback(
    (opts: Parameters<F>[0]) => action.creator(opts)(dispatch),
    [dispatch, action]
  );
  return [state, fetch] as [typeof state, typeof fetch]; // typescript thinks it's an array of union types otherwise
};
