export enum PageRoutes {
  Root = "/",
  Home = "/home",
  Services = "/services",
  Portfolio = "/portfolio",
  About = "/about",
  Contact = "/contact",
  Beach = "/beach",
}

export enum ServicesPageRoutes {
  HomeStaging = "/services-home-staging",
  InteriorDesign = "/services-interior-design",
  InstantHome = "/services-instant-home",
}
