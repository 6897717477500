import React from "react";
import {
  makeStyles,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
  FormLabel,
} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Typography from "../components/Typography";
import { Formik, FormikProps } from "formik";
import FlexBox from "../components/FlexBox";
import contactPageImage1 from "../media/contact/contactPage1.png";
import { ReactComponent as LongArrowRight } from "../media/longArrowRight.svg";
import PageTitle from "../components/PageTitle";
import {
  ContactForm,
  ContactReason,
  initialContactForm,
  validateContactForm,
} from "../models/ContactForm";
import { postContactForm } from "../api/googleSheetsApiClient";
import {
  anyIsError,
  anyIsIdle,
  anyIsPending,
  anyIsSuccess,
  useFetchedResource,
} from "../utils/fetchers";
import { useRouteMatch } from "react-router-dom";
import { PageRoutes } from "../models/routes";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
  },
  formContainer: {
    margin: "115px 15px",
    background: "#222223",
    zIndex: 1,
    padding: theme.spacing(3),
    maxWidth: 580,
  },
  contactContentContainer: {
    position: "relative",
    width: "100%",
    justifyContent: "center",
  },
  textInput: {
    "& *": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderColor: "white",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "white",
    },
  },
  toggleButtonGroup: {
    "& > .MuiToggleButton-root": {
      "& > .MuiToggleButton-label": {
        color: "rgba(255, 255, 255, 0.38)",
      },
      border: "1px solid rgba(255, 255, 255, 0.12)",
      "&.Mui-selected": {
        "& > .MuiToggleButton-label": {
          color: "white",
        },
        background: "rgba(255, 255, 255, 0.12)",
      },
    },
  },
  boldWhiteText: {
    fontWeight: 600,
    color: "white",
  },
  arrowSvg: {
    fill: "white",
    height: "1em",
    marginLeft: theme.spacing(3),
  },
  fullWidthCentered: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  formLabel: {
    color: "white",
    marginBottom: theme.spacing(2),
  },
  contactBackgroundImage: {
    position: "fixed",
    background: `url(${contactPageImage1}) no-repeat center center / cover`,
    // width: '100%',
    // height: 'calc(100% + 106px)',
    top: 106,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const formatPhoneNumber = (numStr: string) => {
  // valid phone number lengths are 10, 11, or 13
  const len = numStr.length;
  if (len === 0) {
    return numStr;
  } else if (len <= 3) {
    return `(${numStr.slice(0, 3)}`;
  } else if (len <= 6) {
    return `(${numStr.slice(0, 3)}) ${numStr.slice(3, 6)}`;
  } else if (len <= 10) {
    return `(${numStr.slice(0, 3)}) ${numStr.slice(3, 6)}-${numStr.slice(
      6,
      10
    )}`;
  } else {
    return `+${numStr.slice(0, len - 10)} (${numStr.slice(
      len - 10,
      len - 7
    )}) ${numStr.slice(len - 7, len - 4)}-${numStr.slice(len - 4, len)}`;
  }
};

const getFormikProps = (
  props: FormikProps<ContactForm>,
  name: keyof ContactForm
) => ({
  name,
  onBlur: props.handleBlur,
  onChange: props.handleChange,
  value: props.values[name],
});

type StagingFormProps = FormikProps<ContactForm>;
const StagingForm = (props: StagingFormProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={4}>
        <TextField
          className={classes.textInput}
          fullWidth
          type="number"
          label="Approx. Sq Ft"
          inputProps={{ step: 100 }}
          {...getFormikProps(props, "sqft")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          className={classes.textInput}
          fullWidth
          type="number"
          label="Bedrooms"
          {...getFormikProps(props, "numBedrooms")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          className={classes.textInput}
          fullWidth
          type="number"
          label="Bathrooms"
          inputProps={{ step: 0.5 }}
          {...getFormikProps(props, "numBathrooms")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.textInput}
          fullWidth
          label="Location"
          placeholder="Address or Neighborhood..."
          {...getFormikProps(props, "location")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.textInput}
          fullWidth
          label="Approx. Date of Completion"
          placeholder="The week of ..."
          {...getFormikProps(props, "date")}
        />
      </Grid>
    </>
  );
};

const MessageField = (props: FormikProps<ContactForm>) => {
  const classes = useStyles();
  const messageLabel =
    props.values.reason === ContactReason.None
      ? "Message"
      : props.values.reason === ContactReason.InteriorDesign
      ? "Describe the project"
      : "Tell me about the property";
  return (
    <Grid item xs={12}>
      <TextField
        className={classes.textInput}
        fullWidth
        multiline
        label={messageLabel}
        aria-label={messageLabel}
        {...getFormikProps(props, "message")}
      />
    </Grid>
  );
};

const InteriorDesignForm = (props: FormikProps<ContactForm>) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <TextField
          className={classes.textInput}
          fullWidth
          multiline
          label="Which room(s)?"
          aria-label="What rooms are you thinking of designing?"
          {...getFormikProps(props, "rooms")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.textInput}
          fullWidth
          label="Do you have a budget estimate?"
          aria-label="Do you have an estimate of your budget?"
          {...getFormikProps(props, "budget")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.textInput}
          fullWidth
          label="Approx. date of completion"
          placeholder="The week of ..."
          {...getFormikProps(props, "date")}
        />
      </Grid>
    </>
  );
};

const ContactPage = () => {
  const classes = useStyles();
  const [response, submitContactForm] = useFetchedResource(postContactForm);
  const isMatch = useRouteMatch(PageRoutes.Contact);

  return (
    <FlexBox className={classes.contactContentContainer}>
      {isMatch && <div className={classes.contactBackgroundImage} />}
      <Formik
        initialValues={initialContactForm}
        validate={validateContactForm}
        onSubmit={submitContactForm}
      >
        {(props) => (
          <FlexBox flexDirection="column" className={classes.formContainer}>
            <PageTitle className={classes.boldWhiteText}>Contact</PageTitle>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  label="Name"
                  {...getFormikProps(props, "name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Tooltip title="We will never send you spam or give your information to a third party.">
                  <TextField
                    className={classes.textInput}
                    fullWidth
                    label="Email"
                    aria-label="Email"
                    value={props.values.email}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                    name="email"
                    type="email"
                    error={!!(props.touched.email && props.errors.email)}
                    helperText={
                      (props.touched.email && props.errors.email) || " "
                    }
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.textInput}
                  fullWidth
                  label="Phone"
                  aria-label="Phone"
                  value={formatPhoneNumber(props.values.phone)}
                  onBlur={props.handleBlur}
                  onChange={(evt) => {
                    const onlyNumbers = evt.target.value.replace(/\D/g, "");
                    return props.setFieldValue(
                      "phone",
                      onlyNumbers.slice(0, 15)
                    );
                  }}
                  name="phone"
                  type="tel"
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.formLabel}>
                  Reason for contacting
                </FormLabel>
                <ToggleButtonGroup
                  value={props.values.reason}
                  exclusive
                  onChange={(evt, newValue) =>
                    props.setFieldValue("reason", newValue)
                  }
                  aria-label="Reason for contacting"
                  className={classes.toggleButtonGroup}
                >
                  <ToggleButton
                    value={ContactReason.HomeStaging}
                    aria-label="home staging"
                  >
                    Home Staging
                  </ToggleButton>
                  <ToggleButton
                    value={ContactReason.InteriorDesign}
                    aria-label="interior design"
                  >
                    Interior Design
                  </ToggleButton>
                  <ToggleButton
                    value={ContactReason.None}
                    aria-label="just saying hi"
                  >
                    Just Saying Hi
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <MessageField {...props} />
              {props.values.reason === ContactReason.HomeStaging ? (
                <StagingForm {...props} />
              ) : props.values.reason === ContactReason.InteriorDesign ? (
                <InteriorDesignForm {...props} />
              ) : null}
              <Grid item xs={12}>
                {anyIsIdle(response) ? (
                  <Button fullWidth onClick={props.submitForm}>
                    <Typography
                      header
                      variant="h5"
                      className={classes.boldWhiteText}
                    >
                      Send
                    </Typography>
                    <LongArrowRight className={classes.arrowSvg} />
                  </Button>
                ) : (
                  <div className={classes.fullWidthCentered}>
                    {anyIsPending(response) ? (
                      <CircularProgress />
                    ) : anyIsSuccess(response) ? (
                      <Typography
                        header
                        variant="h5"
                        className={classes.boldWhiteText}
                      >
                        Sent!
                      </Typography>
                    ) : anyIsError(response) ? (
                      <Typography color="error" header>
                        Our apologies. Something went wrong. Please try again.
                      </Typography>
                    ) : null}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <FlexBox justifyContent="center">
                  <Typography
                    header
                    variant="h5"
                    className={classes.boldWhiteText}
                  >
                    Initial Consultation Is Complementary
                  </Typography>
                </FlexBox>
              </Grid>
            </Grid>
          </FlexBox>
        )}
      </Formik>
    </FlexBox>
  );
};

export default ContactPage;
