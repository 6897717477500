import React from "react";
import classNames from "classnames";
import { Button, makeStyles, useMediaQuery } from "@material-ui/core";
import Typography from "../components/Typography";
import FlexBox from "../components/FlexBox";
import servicesPageImage4 from "../media/services/servicesPage4.png";
import NavLeftRight from "../components/NavLeftRight";
import { PageRoutes, ServicesPageRoutes } from "../models/routes";
import { useHistory, useRouteMatch } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import GetStartedLink from "../components/GetStartedLink";
import { UP_BREAK, useStyles } from "../styles";
import BlurbHeader from "../components/BlurbHeader";

const useServicesPageStyles = makeStyles((theme) => ({
  blurbContainer: {
    background: "#000000c3",
    color: "#efefef",
    padding: theme.spacing(4),
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
    [UP_BREAK]: {
      marginRight: `-${theme.spacing(8)}px`,
      paddingRight: theme.spacing(12),
    },
  },
}));

const ServicesPage = () => {
  const classes = {
    ...useStyles(),
    ...useServicesPageStyles(),
  };
  const isSmallOrLarger = useMediaQuery(UP_BREAK);
  const history = useHistory();
  const isOnRootPage = useRouteMatch({
    path: PageRoutes.Root,
    exact: true,
  });

  return (
    <FlexBox flexDirection="column">
      <FlexBox className={classes.titleAndDescriptionContainer}>
        <PageTitle>Services</PageTitle>
      </FlexBox>
      <FlexBox className={classes.imageAndBlurbContainer}>
        <div className={classes.blurbContainer}>
          <Typography>
            Ty Karges Home is a Home Staging and Interior Design firm located in
            San Jose, California.
          </Typography>
          <Typography>
            We strive to deliver curated styles, superior customer service, and
            hassle-free project management because we know your time is
            valuable.
          </Typography>
          <br />
          <br />
          <BlurbHeader>
            <b>{`Home Staging. `}</b>
          </BlurbHeader>
          <Typography component="span">
            We stage homes in the entire San Francisco Bay area from Marin
            Country to Gilroy.
          </Typography>
          <br />
          <br />
          <BlurbHeader>
            <b>{`Interior Design. `}</b>
          </BlurbHeader>
          <Typography component="span">
            We accept Interior Design projects throughout the Silicon Valley
            area.
          </Typography>
          <GetStartedLink />
        </div>
        <img
          className={classNames(classes.image, {
            [classes.noShowOnMobile]: !!isOnRootPage,
          })}
          src={servicesPageImage4}
          alt="Mid-century modern bedroom"
        />
      </FlexBox>
      {isSmallOrLarger && (
        <FlexBox className={classes.boxButtonsContainer}>
          <Button
            className={classes.boxButton}
            onClick={() => history.push(ServicesPageRoutes.HomeStaging)}
          >
            <div className={classes.boxButtonGridItem1}>
              <Typography
                header
                variant="h5"
                className={classes.boxButtonLabelText}
              >
                Home Staging
              </Typography>
            </div>
            <div className={classes.boxButtonGridItem2}>
              <Typography>
                For agents, developers, and homeowners to expedite sale at
                record high prices.
              </Typography>
            </div>
          </Button>
          <Button
            className={classes.boxButton}
            onClick={() => history.push(ServicesPageRoutes.InteriorDesign)}
          >
            <div className={classes.boxButtonGridItem1}>
              <Typography
                header
                variant="h5"
                className={classes.boxButtonLabelText}
              >
                Interior Design + Renovation
              </Typography>
            </div>
            <div className={classes.boxButtonGridItem2}>
              <Typography>
                1-stop shop, turnkey interior design services to optimize your
                space and maximize your lifestyle.
              </Typography>
            </div>
          </Button>
          <Button
            className={classes.boxButton}
            onClick={() => history.push(ServicesPageRoutes.InstantHome)}
          >
            <div className={classes.boxButtonGridItem1}>
              <Typography
                header
                variant="h5"
                className={classes.boxButtonLabelText}
              >
                Instant Home
              </Typography>
            </div>
            <div className={classes.boxButtonGridItem2}>
              <Typography>
                Hassle-free furniture + home good selection services for your
                vacation rentals and model apartments.
              </Typography>
            </div>
          </Button>
        </FlexBox>
      )}
      {!isOnRootPage && (
        <NavLeftRight
          left={{ label: "View Portfolio", href: PageRoutes.Portfolio }}
          right={{ label: "Contact Us", href: PageRoutes.Contact }}
        />
      )}
    </FlexBox>
  );
};

export default ServicesPage;
