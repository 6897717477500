import { makeStyles, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import FlexBox from "../../components/FlexBox";
import ImageCard from "../../components/ImageCard";
import NavLeftRight from "../../components/NavLeftRight";
import PageTitle from "../../components/PageTitle";
import Typography from "../../components/Typography";
import interiorDesignImage from "../../media/portfolio/3D/Bathroom.png";
import interiorDesignGif from "../../media/portfolio/3D/Bathroom.gif";
import { PageRoutes } from "../../models/routes";
import { IS_TOUCH_SCREEN } from "../../utils/constants";
import GetStartedLink from "../../components/GetStartedLink";

const BREAKPOINT = 960;
const DOWN_BREAK = `@media (max-width: ${BREAKPOINT - 0.05}px)`;
const UP_BREAK = `@media (min-width: ${BREAKPOINT}px)`;

const useStyles = makeStyles((theme) => ({
  homeStagingContentContainer: {
    flexDirection: "column",
    width: "100%",
    [UP_BREAK]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [DOWN_BREAK]: {
      paddingLeft: theme.spacing(4),
    },
  },
  title2: {
    textAlign: "center",
  },
  subPage: {
    position: "relative",
    alignItems: "center",
  },
  columnDirection: {
    flexDirection: "column",
  },
  mediaContainer: {
    height: "60vh",
    display: "flex",
    alignItems: "center",
    [DOWN_BREAK]: {
      height: "auto",
    },
  },
  media: {
    objectFit: "contain",
    width: "600px",
    [DOWN_BREAK]: {
      width: "100vw",
      height: "auto",
    },
  },
  imageOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& ~ .MuiCardActionArea-focusHighlight": {
      display: "none",
    },
  },
  blurbContainer: {
    background: "#000000c3",
    color: "#efefef",
    padding: theme.spacing(4),
    [DOWN_BREAK]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  leftShift: {
    position: "relative",
    left: `-${theme.spacing(6)}px`,
    right: 0,
    paddingLeft: theme.spacing(12),
  },
  boldStatements: {
    whiteSpace: "pre-wrap",
    fontSize: 26,
  },
  ghostBox: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#ffffff7d",
    top: theme.spacing(8),
    left: theme.spacing(8),
  },
  imageText: {
    zIndex: 1,
  },
}));

const InteriorDesignPage = () => {
  const isSmallOrLarger = useMediaQuery(UP_BREAK);
  const classes = useStyles();
  const [mouseOverImage, setMouseOverImage] = useState(false);
  const showGif = IS_TOUCH_SCREEN || mouseOverImage;
  const actualImage = showGif ? interiorDesignGif : interiorDesignImage;
  return (
    <FlexBox flexDirection="column">
      <FlexBox className={classes.homeStagingContentContainer}>
        <PageTitle className={classes.title}>
          Interior Design / Renovation
        </PageTitle>
        <FlexBox
          className={classNames(classes.subPage, {
            [classes.columnDirection]: !isSmallOrLarger,
          })}
        >
          <FlexBox
            flexShrink={0}
            className={classes.mediaContainer}
            onMouseEnter={() => setMouseOverImage(true)}
            onMouseLeave={() => setMouseOverImage(false)}
          >
            <ImageCard image={actualImage} className={classes.media}>
              <div className={classes.imageOverlay}>
                {!showGif && (
                  <>
                    <div className={classes.ghostBox} />
                    <Typography
                      header
                      variant="h2"
                      className={classes.imageText}
                    >
                      <b>Why</b>
                    </Typography>
                    <Typography
                      header
                      variant="h2"
                      className={classes.imageText}
                    >
                      <b>Work</b>
                    </Typography>
                    <Typography
                      header
                      variant="h2"
                      className={classes.imageText}
                    >
                      <b>With Us?</b>
                    </Typography>
                  </>
                )}
              </div>
            </ImageCard>
          </FlexBox>
          <div
            className={classNames(classes.blurbContainer, {
              [classes.leftShift]: isSmallOrLarger,
            })}
          >
            <Typography
              header
              variant="h6"
              component="span"
              className={classes.boldStatements}
            >
              <b>{`Signature Style Curated. `}</b>
            </Typography>
            <Typography component="span">
              We deliver designs with personalized styles and functionalities
              that are uniquely yours. We listen to your needs and desires and
              turn them into the designs of your dreams. No standard material or
              layout presets.
            </Typography>
            <br />
            <br />
            <Typography
              header
              variant="h6"
              component="span"
              className={classes.boldStatements}
            >
              <b>{`Quality-Work Delivered. `}</b>
            </Typography>
            <Typography component="span">
              We design with engineering precision. Our designs are then
              executed by highly-vetted licensed professionals to ensure that
              you receive the highest quality of work.
            </Typography>
            <br />
            <br />
            <Typography
              header
              variant="h6"
              component="span"
              className={classes.boldStatements}
            >
              <b>{`Time Saved. `}</b>
            </Typography>
            <Typography component="span">
              Your time is valuable. Let us be that 1-stop shop to get your home
              looking at its best. We provide turnkey project management from
              start to finish. If you just want to hand us the key and move in
              when it is ready, no problem!
            </Typography>
            <GetStartedLink />
          </div>
        </FlexBox>
      </FlexBox>
      <FlexBox className={classes.homeStagingContentContainer}>
        <PageTitle className={classes.title2}>
          Our Turnkey Services Include:
        </PageTitle>
        <FlexBox justifyContent="center">
          <ul>
            <li>
              End-to-End Project Management from Start to Project Completion
            </li>
            <li>Procurement, consolidation, demolition and installation.</li>
            <li>Space Layout Planning </li>
            <li>3D Interior Drawing with Engineering Precision</li>
            <li>Residential Designs including Kitchen and Bath Designs</li>
            <li>Designs with Smart-Home Integration</li>
            <li>Custom Cabinetry and Finish Carpentry</li>
            <li>Custom Window Treatments</li>
            <li>All Interior Paints, Finishes and Materials</li>
            <li>Lighting, Plumbing and Hardware </li>
            <li>Art and Furniture Curation</li>
            <li>
              + Many more. Contact us if you do not see what you are looking for
              on the list.
            </li>
          </ul>
        </FlexBox>
      </FlexBox>
      <NavLeftRight
        left={{ label: "View All Services", href: PageRoutes.Services }}
        right={{ label: "View Portfolio", href: PageRoutes.Portfolio }}
      />
    </FlexBox>
  );
};

export default InteriorDesignPage;
