import { makeStyles } from "@material-ui/core";
import React from "react";
import { FC } from "react";
import { ReactComponent as LongArrowRight } from "../media/longArrowRight.svg";
import Typography from "./Typography";

const useStyles = makeStyles((theme) => ({
  redText: {
    fontWeight: 600,
    fontSize: "24px",
    color: theme.palette.primary.main,
  },
  arrowLeft: {
    fill: theme.palette.primary.main,
    height: "1em",
    transform: "scale(-1)",
    marginRight: theme.spacing(1),
  },
  arrowRight: {
    fill: theme.palette.primary.main,
    height: "1em",
    marginLeft: theme.spacing(1),
  },
}));

export const RedArrowRight: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Typography header className={classes.redText}>
        {children}
      </Typography>
      <LongArrowRight color="primary" className={classes.arrowRight} />
    </>
  );
};

export const RedArrowLeft: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <LongArrowRight color="primary" className={classes.arrowLeft} />
      <Typography header className={classes.redText}>
        {children}
      </Typography>
    </>
  );
};
